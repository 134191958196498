import { Container, Box, Typography, Paper, Avatar, TableContainer, Table, TableRow, TableCell } from "@mui/material";
import {useParams} from 'react-router-dom'
import CryptoJS from "crypto-js";
import React from "react";
import { Api, ImageApi } from "../api";
import error from './../assets/error.png'
import { Check } from "@mui/icons-material";
export default function Verify(){
    const param = useParams()
    const id =  CryptoJS.AES.decrypt(param.id, 'test');
    const [data, setData] = React.useState([])
    console.log('dec',id)
    React.useEffect(()=>{

        try {
           const id_post = JSON.parse(id.toString(CryptoJS.enc.Utf8))
           console.log('tes',id_post)
           fetch(Api+'studentData',{
            method : 'POST',
            body : JSON.stringify({
                id : id_post
            })
            })
            .then(res=>res.json())
            .then(res=>setData(res))
            .catch(err=>{
            console.log(err)
            })
        } catch (error) {
            console.log(error)
        }

       

        console.log(data)
        
    },[])
    return(
        <Container style={{display: 'flex', flexDirection : 'column',paddingTop: 100, justifyContent: 'center', alignItems: 'center'}}>
        {
            data.length > 0 ? (
                <Box>
                {
                    data.map((item,index)=>
                        <Paper key={index} style={{padding: 30,display: 'flex', flexDirection : 'column', justifyContent: 'center', alignItems: 'center'}} variant="outlined">
                            <Avatar component={Paper} style={{width : 150, height : 150}}>
                                <img src={ImageApi+item.img} style={{width: '100%'}} />
                            </Avatar>
                            <div style={{display : 'flex', color : 'green', alignItems : 'center',marginTop: 10}}>
                               <Check  />
                                <Typography component={'strong'} variant="h6">Verified Student</Typography>
                            </div>
                            <TableContainer component={Paper} variant="outlined">
                                <Table>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell>{item.name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>F/M Name</TableCell>
                                        <TableCell>{item.f_name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Date of Birth</TableCell>
                                        <TableCell>{item.dob}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Course Name</TableCell>
                                        <TableCell>{item.c_name}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Course Details</TableCell>
                                        <TableCell>{item.c_details}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Result</TableCell>
                                        <TableCell>{item.res}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell>Status</TableCell>
                                        <TableCell>{item.status}</TableCell>
                                    </TableRow>
                                </Table>
                            </TableContainer>
                        </Paper>
                    )
                }
                </Box>
            ): (
                <Box>
                    <img src={error} width={200} height={200} />
                    <Typography>
                        Sorry, this is not a valid certificate
                    </Typography>
                </Box>
            )
        }
        </Container>
    )
}