import { Container,Typography, Button } from "@mui/material";
import image from '../assets/qr.png'


export default function Home(){


    return(
        <Container style={{display :'flex',justifyContent : 'center', flexDirection : 'column',alignItems: 'center', height: '90vh'}}>
            <img src={image} width="200" height="200" />
            <Typography variant="h6">To verify a certificate you need to scan a QR code which is avialable at student certificate.</Typography>
            <a href="https://ditamc.in/" target={'_blank'} >
                <Button
                    variant="contained"
                    color="primary"
                    style={{marginTop: 20,textDecoration : 'none'}}

                >
                    Contact us
                </Button>
            </a>
        </Container>
    )
}