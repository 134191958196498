
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Verify from './components/Verify';
import logo from './assets/logo.jpeg'
import {AppBar, Toolbar, Typography} from '@mui/material'
function App() {
  
  return (
    <div className="App">
      <AppBar
        elevation={0}
        color="inherit"
        position="fixed"
      >
       <Toolbar>
       <img src={logo} width={60} height={60} />
       <div style={{marginLeft: 10}}>
       <Typography variant="h5"  component={'strong'}>Digital Institute of Technology </Typography>
       <Typography>Student Verification Portal</Typography>
       </div>
       </Toolbar>
      </AppBar>
      <BrowserRouter>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/:id' element={<Verify />} />
          </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
